import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import './App.css';

function App() {
  const progressPercent = 0.05 * 100;

  return (
    <div className='App'>
      <header className='App-header'>
        <Container maxWidth='sm'>
          <Typography variant='h6' gutterBottom>
            {/* STATUS: %{progressPercent} */}
            CONSTRUCTING: {progressPercent}%
          </Typography>
          <LinearProgress variant='determinate' value={progressPercent} />
        </Container>
      </header>
    </div>
  );
}

export default App;
